import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details10',
  templateUrl: './blogs-details10.component.html',
  styleUrls: ['./blogs-details10.component.css']
})
export class BlogsDetails10Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails10Component) {  
  context.title = 'Business Applications of Machine Learning';
  context.description = 'Discover how machine learning is transforming business operations across industries, enhancing decision-making and predictive capabilities.';
  context.keywords = 'Machine Learning, Business';
  context.seoTags();
}
