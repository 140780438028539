import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';

@Component({
  selector: 'app-blogs-details2',
  templateUrl: './blogs-details2.component.html',
  styleUrls: ['./blogs-details2.component.css']
})
export class BlogsDetails2Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails2Component) {  
  context.title = 'Stages in Business Digital Transformation';
  context.description = 'Discover the key stages every business must go through for successful digital transformation.';
  context.keywords = 'Business Digital Transformation, Stages';
  context.seoTags();
}
