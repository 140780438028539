import { Component } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-information-security',
  templateUrl: './information-security.component.html',
  styleUrls: ['./information-security.component.css']
})
export class InformationSecurityComponent extends BaseClass {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
  override ngAfterViewInit(): void {
    //function Hidden courses here  
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:18';
    window.open(url, '_blank');
  }
}


function init(context: InformationSecurityComponent) {
  context.title = 'Information Security - Expert Learning with Skillikz';
  context.description = 'Strengthen business compliance with our CPD accredited cyber and data security courses, covering GDPR, Cyber Security, and more. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited.';
  context.keywords = 'Information Security Services, Cybersecurity Solutions, Data Protection, Network Security, Risk Management in Security, IT Security Consulting, Cloud Security, Information Security Compliance, Cybersecurity Audits, Threat Detection and Response, Security Vulnerability Assessment, Data Encryption Services, Endpoint Security Solutions, Identity and Access Management (IAM), Security Information and Event Management (SIEM), Incident Response Planning, Security Risk Assessment, Penetration Testing Services, Firewall Security Solutions, Security Awareness Training';
  context.seoTags();
}