import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details40',
  templateUrl: './blogs-details40.component.html',
  styleUrls: ['./blogs-details40.component.css']
})
export class BlogsDetails40Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails40Component) {  
  context.title = 'Emerging Trends in Big Data and Analytics';
  context.description = 'Big Data, Data Analytics, Emerging Trends, Data Science';
  context.keywords = "Learn about the emerging trends in Big Data and Analytics and how they're shaping industries and decision-making.";
  context.seoTags();
}
