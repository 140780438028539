import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details35',
  templateUrl: './blogs-details35.component.html',
  styleUrls: ['./blogs-details35.component.css']
})
export class BlogsDetails35Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails35Component) {  
  context.title = 'Hyperautomation in Business: The Future of Automation';
  context.description = 'Hyperautomation, Business Automation, Future of Hyperautomation';
  context.keywords = 'Explore how hyperautomation is transforming business operations by integrating advanced technologies for better results.';
  context.seoTags();
}
