import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';

@Component({
  selector: 'app-blogs-details56',
  templateUrl: './blogs-details56.component.html',
  styleUrls: ['./blogs-details56.component.css']
})
export class BlogsDetails56Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails56Component) {  
  context.title = 'Static to Strategic: A Digital Transformation Guide';
  context.description = 'Discover how to shift from static operations to a strategic digital transformation for long-term success.';
  context.keywords = 'Digital Transformation, Guide';
  context.seoTags();
}
