import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details39',
  templateUrl: './blogs-details39.component.html',
  styleUrls: ['./blogs-details39.component.css']
})
export class BlogsDetails39Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails39Component) {  
  context.title = 'Latest Trends in Backend Technologies for Software Development';
  context.description = 'Backend Development, Backend Trends, Software Architecture';
  context.keywords = 'Explore the latest backend technologies in software development, including frameworks, databases, and server-side tools.';
  context.seoTags();
}
