import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details37',
  templateUrl: './blogs-details37.component.html',
  styleUrls: ['./blogs-details37.component.css']
})
export class BlogsDetails37Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails37Component) {  
  context.title = 'Open Banking and the Rise of Financial Technology';
  context.description = 'Open Banking, Financial Technology, FinTech, Banking Innovation';
  context.keywords = "Discover the impact of open banking on financial services and how it's enabling innovation through digital platforms.";
  context.seoTags();
}