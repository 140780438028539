import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details27',
  templateUrl: './blogs-details27.component.html',
  styleUrls: ['./blogs-details27.component.css']
})
export class BlogsDetails27Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails27Component) {  
  context.title = 'Event-Driven Architecture in Software Development';
  context.description = 'Explore how event-driven architecture is transforming the way software applications are designed and developed.';
  context.keywords = 'Event-Driven Architecture, Software';
  context.seoTags();
}
