import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details17',
  templateUrl: './blogs-details17.component.html',
  styleUrls: ['./blogs-details17.component.css']
})
export class BlogsDetails17Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails17Component) {  
  context.title = 'Cloud-Native Development in Product Engineering';
  context.description = 'Learn about the benefits of cloud-native development in product engineering for faster deployment and scalability.';
  context.keywords = 'Cloud-Native Development, Product Engineering';
  context.seoTags();
}
