import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details26',
  templateUrl: './blogs-details26.component.html',
  styleUrls: ['./blogs-details26.component.css']
})
export class BlogsDetails26Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails26Component) {  
  context.title = 'Benefits and Challenges of Microservice Architecture';
  context.description = 'Learn about the benefits and challenges of using microservice architecture in software development to improve scalability and maintainability.';
  context.keywords = 'Microservice Architecture, Software';
  context.seoTags();
}
