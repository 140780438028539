import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details19',
  templateUrl: './blogs-details19.component.html',
  styleUrls: ['./blogs-details19.component.css']
})
export class BlogsDetails19Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails19Component) {  
  context.title = 'New Trends in IT Product Experience and Design';
  context.description = 'Explore the latest trends in IT product experience and design that are shaping customer expectations and product success.';
  context.keywords = 'IT Product Experience, Design Trends';
  context.seoTags();
}
