import { Component, HostListener, OnInit } from '@angular/core';
import { BaseClass } from '../../modules/core/base/base-class';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseClass implements OnInit {

  
  public anim: any;
  public anim2: any;
  second: boolean = false;
  currentSliderIndex: number = 0;
  loadedSliders: number[] = [];

  videoSrc: string = "";
  
  @HostListener('window:resize', ['$event'])onResize(event) {
    if (window.innerWidth > 768.5)
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    else {
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
    }
  }

  constructor() {
    super()

  }

  ngOnInit(): void {
    init(this);

    if (window.innerWidth > 768.5)
      this.videoSrc = "assets/images/banner/home-page-banner-large.mp4";
    else {
      this.videoSrc = "assets/images/banner/home-page-banner-small.mp4";
    }
 
  }

   

  updateDotsCss() {
    let dots = document.getElementsByClassName("dot");
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    dots[this.currentSliderIndex].className += " active";
  }



}



function init(context: HomeComponent) {
  context.title = 'Skillikz - Transform Your Skills with Expert Courses';
  context.description = "Unlock your potential with Skillikz's expert-led courses designed to help you master in-demand skills.";
  context.keywords = 'Skillikz, Expert Courses';
  context.seoTags();
}







