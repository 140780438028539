import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent extends BaseClass implements OnInit {

  constructor() {
    super()
   }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: PrivacyPolicyComponent) {  
  context.title = 'Privacy Policy - Skillikz';
  context.description = "Read Skillikz's privacy policy to understand how we collect, use, and protect your data.";
  context.keywords = 'Skillikz Privacy Policy';
  context.seoTags();
}
