import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})

export class TermsOfUseComponent extends BaseClass implements OnInit {
  constructor() {
    super()
   }

  ngOnInit(): void {
    init(this);
  }
 
}

function init(context: TermsOfUseComponent) {  
  context.title = 'Terms of Use - SKILLIKZ';
  context.description = "Review Skillikz's terms of use for website and services.";
  context.keywords = 'Skillikz Terms of use';
  context.seoTags();
}
