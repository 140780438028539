import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details23',
  templateUrl: './blogs-details23.component.html',
  styleUrls: ['./blogs-details23.component.css']
})
export class BlogsDetails23Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails23Component) {  
  context.title = 'Cloud Modernisation Strategy';
  context.description = 'Discover how cloud modernisation can enhance business agility and reduce costs in an ever-evolving digital world.';
  context.keywords = 'Cloud Modernisation, Strategy';
  context.seoTags();
}
