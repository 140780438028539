import { AfterContentInit, Component } from "@angular/core";
import { BaseClass } from "./base-class";
@Component({
    template: ""
})

export class BlogsBaseClass extends BaseClass implements AfterContentInit {
    textAndElementMap: Map<string, Element> = new Map()

    ngAfterContentInit(): void {
        const headings = document.querySelectorAll(".tagging");
        headings.forEach(e => {
            this.textAndElementMap.set(e.innerHTML, e);
        })
    }

    onContentClick(key: string) {
        const acc = document.querySelector('.accordion');
        this.handleAccordionClick(acc);
        setTimeout(() => {
            const element = this.textAndElementMap.get(key);
            let position = element.getBoundingClientRect();
            window.scrollTo(position.left, position.top + window.scrollY - 0)
        }, 200);
    }
}