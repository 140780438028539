<div class="collapse-panel">
  <div class="accordion" (click)="handleAccordionClick($event.currentTarget)">
    <a href="javascript:void(0)" role="button">
      <p>Table of Content</p>
    </a>
  </div>
  <div class="panel">
    <div class="">
      <ul class="listOfContent">
        <ng-container *ngFor="let item of keys|async;trackBy:identity">
          <li><a role="button" (click)="onContentClick(item)">{{item}}</a></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>