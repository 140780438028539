import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details18',
  templateUrl: './blogs-details18.component.html',
  styleUrls: ['./blogs-details18.component.css']
})
export class BlogsDetails18Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: BlogsDetails18Component) {  
  context.title = 'DevOps and Agile Development';
  context.description = 'Discover how DevOps and Agile methodologies are revolutionizing software development and enhancing business agility.';
  context.keywords = 'DevOps, Agile Development';
  context.seoTags();
}