import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-financial-compliance',
  templateUrl: './financial-compliance.component.html',
  styleUrls: ['./financial-compliance.component.css']
})
export class FinancialComplianceComponent extends BaseClass implements OnInit {

  constructor(public router: ActivatedRoute, private location: Location) { super() }

  ngOnInit(): void {
    init(this);
    this.showLocationTab(null, 'tabOne');
    this.router.queryParams.subscribe(params => {
      if (params && params['tabName']) {
        this.showLocationTab(null, params['tabName']);
        this.location.replaceState('/financial-compliance');
      }
    });
  }
  override ngAfterViewInit(): void {
    //function Hidden courses here
  }
  buybundels(){
    const url = 'https://lms.skillikz.com/catalog/index/group:19';
    window.open(url, '_blank');
  }
}

function init(context: FinancialComplianceComponent) {
  context.title = 'Financial Compliance - Expert Learning with Skillikz';
  context.description = 'Enhance business integrity with our CPD accredited modules. Combat economic crime with AML, Anti-Bribery, Prevent Duty, and more. Flexible learning, Learn 24x7, intuitive interface, interactive scenarios, and immediate practical application, CPD accredited.';
  context.keywords = 'Financial Compliance Services, Regulatory Compliance Consulting, Financial Risk Management, Compliance Audit Services, Financial Reporting Standards, Anti-Money Laundering (AML), Know Your Customer (KYC), Sarbanes-Oxley Act Compliance, Financial Compliance Solutions, Tax Compliance Services, Governance, Risk, and Compliance (GRC), Financial Regulations Consulting, Internal Control Framework, Compliance Management Systems, SOX Compliance, Financial Compliance Audits, SEC Compliance Services, Fraud Prevention and Detection, Financial Industry Regulations, Compliance Automation Solutions, ';
  context.seoTags();
}
