import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details47',
  templateUrl: './blogs-details47.component.html',
  styleUrls: ['./blogs-details47.component.css']
})
export class BlogsDetails47Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails47Component) {
  context.title = "The Digital Immune System: The Future of Cybersecurity";
  context.description = "Learn about the digital immune system and how it will revolutionize cybersecurity practices in the digital age.";
  context.keywords = 'Cybersecurity, Digital Immune System';
  context.seoTags();
}
