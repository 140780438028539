import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details45',
  templateUrl: './blogs-details45.component.html',
  styleUrls: ['./blogs-details45.component.css']
})
export class BlogsDetails45Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit() {
    init(this);
  }

}
  
function init(context: BlogsDetails45Component) {  
  context.title = 'Challenges in Financial Compliance: A Market Review';
  context.description = 'Explore the ongoing challenges financial institutions face in maintaining compliance, with insights into the current market landscape.';
  context.keywords = 'Financial Compliance, Challenges';
  context.seoTags();
}
