import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';

@Component({
  selector: 'app-blogs-details57',
  templateUrl: './blogs-details57.component.html',
  styleUrls: ['./blogs-details57.component.css']
})
export class BlogsDetails57Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }
  

}

function init(context: BlogsDetails57Component) {  
  context.title = 'The New Technology Investment Paradigm: Maximizing ROI in 2024';
  context.description = 'Learn how businesses can maximize their ROI in 2024 by embracing new technology investment paradigms.';
  context.keywords = 'Technology Investment, ROI 2024';
  context.seoTags();
}
