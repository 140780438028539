import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details52',
  templateUrl: './blogs-details52.component.html',
  styleUrls: ['./blogs-details52.component.css']
})
export class BlogsDetails52Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails52Component) {  
  context.title = 'Overcoming Challenges of Digital Transformation';
  context.description = 'Learn how businesses can navigate and overcome the challenges faced during digital transformation.';
  context.keywords = 'Digital Transformation Challenges';
  context.seoTags();
}
