<div class="stickyWp">
  <div class="tableOfContent-wp">
    <p>Table of Content</p>
    <ul class="listOfContent">
      <ng-container *ngFor="let item of keys|async;trackBy:identity">
        <li><a role="button" (click)="onContentClick(item)">{{item}}</a></li>
      </ng-container>
    </ul>
  </div>
  <app-more-blogs></app-more-blogs>
</div>