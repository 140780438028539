import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent extends BaseClass implements OnInit {

  constructor() {
    super()
   }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: TermsConditionsComponent) {  
  context.title = 'Terms of Use - SKILLIKZ';
  context.description = 'The following Terms of Use and our Privacy Policy and any Agreement you enter into setting out the agreement (“Agreement”) between Skillikz Ltd a company registered in England, with company number 13087133 and registered office at 152-160, Kemp House, London EC1V 2NX (“we”, “us” or “our”) and any person (“you” or “your”) accessing and using our online training platform and its contents (“Skillikz LMS”) and/or our training Learning Content or resources used on your own training platform (“Skillikz SCORM Files”) as set out in an Agreement.';
  context.keywords = 'Software development services London, Software development services UK, Software development services US, Outsourced IT Services London, Outsourced IT Services UK, Outsourced IT Services US, Digital Transformation, Software services, Microsoft services, Salesforce Services, Digital Strategy, IT Strategy, Managed Services, IT Consultants London, Technology Consultants London, Managed IT Services, Digital Compliance, Financial Compliance, IT Audit Services, IT Engineering Services';
  context.seoTags();
}
