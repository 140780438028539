import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs-table-of-content-mobile,[app-blogs-table-of-content-mobile]',
  templateUrl: './blogs-table-of-content-mobile.component.html',
  styleUrls: ['./blogs-table-of-content-mobile.component.css']
})
export class BlogsTableOfContentMobileComponent extends BaseClass implements OnInit {

  constructor() { super() }

  @Input('tableOfContent') textAndElementMap: Map<string, Element> = new Map();
  keys: Observable<string[]>;

  ngOnInit() {
    this.keys = of(Array.from(this.textAndElementMap.keys()));
  }

  identity = (index: number, item: any) => item;
  onContentClick(key: string) {
    const acc = document.querySelector('.accordion');
    this.handleAccordionClick(acc);
    setTimeout(() => {
      const element = this.textAndElementMap.get(key);
      let position = element.getBoundingClientRect();
      window.scrollTo(position.left, position.top + window.scrollY - 0)
    }, 200);
  }
}
