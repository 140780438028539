import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details3',
  templateUrl: './blogs-details3.component.html',
  styleUrls: ['./blogs-details3.component.css']
})
export class BlogsDetails3Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails3Component) {  
  context.title = 'Digitisation Vs Digitalisation Vs Digital Transformation';
  context.description = "Understand the differences between digitisation, digitalisation, and digital transformation in business.";
  context.keywords = 'Digitisation, Digitalisation, Transformation';
  context.seoTags();
}
