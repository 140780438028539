import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details13',
  templateUrl: './blogs-details13.component.html',
  styleUrls: ['./blogs-details13.component.css']
})
export class BlogsDetails13Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails13Component) {  
  context.title = 'AI Powered Compliance Systems';
  context.description = 'Discover how AI-powered compliance systems are helping businesses meet regulatory requirements efficiently and accurately.';
  context.keywords = 'AI, Compliance Systems';
  context.seoTags();
}
