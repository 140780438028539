import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details43',
  templateUrl: './blogs-details43.component.html',
  styleUrls: ['./blogs-details43.component.css']
})
export class BlogsDetails43Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails43Component) {  
  context.title = 'Digital Maturity in UK Adult Social Care';
  context.description = 'Explore how digital maturity is transforming the adult social care sector in the UK, bridging the technological divide.';
  context.keywords = 'Digital Maturity, UK Social Care';
  context.seoTags();
}
