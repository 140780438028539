import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details11',
  templateUrl: './blogs-details11.component.html',
  styleUrls: ['./blogs-details11.component.css']
})
export class BlogsDetails11Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails11Component) {  
  context.title = 'Strategies in Business Process Improvement';
  context.description = 'Learn effective strategies for improving business processes to achieve greater efficiency and performance.';
  context.keywords = 'Business Process Improvement';
  context.seoTags();
}
