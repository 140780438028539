import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details46',
  templateUrl: './blogs-details46.component.html',
  styleUrls: ['./blogs-details46.component.css']
})
export class BlogsDetails46Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}
  
function init(context: BlogsDetails46Component) {
  context.title = "The Rise of Progressive Web Apps (PWAs) in E-commerce";
  context.description = "Discover how PWAs are enhancing customer experience in e-commerce and why they are becoming a game-changer for online businesses.";
  context.keywords = 'Progressive Web Apps, E-commerce';
  context.seoTags();
}

