import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details15',
  templateUrl: './blogs-details15.component.html',
  styleUrls: ['./blogs-details15.component.css']
})
export class BlogsDetails15Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails15Component) {  
  context.title = 'Importance of ERP Systems in Business';
  context.description = 'Learn why ERP systems are crucial to business success, streamlining operations and improving decision-making.';
  context.keywords = 'ERP Systems, Business';
  context.seoTags();
}
