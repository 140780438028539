<!--Header html beginning here-->
<header [ngClass]="{'transparentHeader': isHome}">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1">
        <div class="header-flex">
          <div class="items-logo">
            <a routerLink="/">
              <img src="assets/images/skillikz-logo.svg" class="currentImg" alt="Skillikz logo" width="145" height="29">
              <img src="assets/images/skillikz-white-logo.svg" class="hoverImg" alt="Skillikz white logo" width="145"
                height="29">
            </a>
          </div>
          <div class="navigation">
            <ul>
              <li><a name="nameId" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                  (click)="onSubMenuClick(0)" [ngClass]="{active: selectedMenuIndex==0}">Home</a></li>
              <li class="dropDown" (mouseenter)="openSubMenu($event)" (mouseleave)="closeSubMenu($event)">
                <a href="javascript:void(0)" role="button" [ngClass]="{active: selectedMenuIndex==1}">Services</a>
                <!-- ---large mene beginning here--- -->
                <ul>
                  <li class="circleBg">
                    <div class="menu-lg-container">
                      <div class="row-flex" (click)="onSubMenuClick(1)">
                        <div class="items-col-8 menuLink">
                          <a routerLink="business-digital-transformation">
                            <div class="nav-card servMenuBg2">
                              <div class="">
                                <h2>Business Digital Transformation</h2>
                                <p>Analyzing Business and Technology compelling events to leverage best of both...</p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div class="items-col-3 menuLink">

                          <a routerLink="product-engineering">
                            <div class="nav-card servMenuBg1">
                              <div class="">
                                <h2>Product Engineering</h2>
                                <p>Stay ahead of competition through innovation led engineering services...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3 menuLink">
                          <a routerLink="technology-consulting">
                            <div class="nav-card servMenuBg4">
                              <div class="">
                                <h2>Consulting</h2>
                                <p>Technology is no longer just a tool; it's a strategic enabler. It reshapes...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                       
                        <div class="items-col-3 menuLink">
                          <a routerLink="staff-augmentation">
                            <div class="nav-card servMenuBg3">
                              <div class="">
                                <h2>Staff Augmentation</h2>
                                <p>Empowering Your Projects with Skilled IT Professionals...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3 menuLink">
                          <a routerLink="learning-services">
                            <div class="nav-card servMenuBg5">
                              <div class="">
                                <h2>Learning</h2>
                                <p>We provide the latest online learning system and material that will help you stay ahead...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        

                        <div class="items-col-9">
                          <div class="md-link-wp">
                            <div class="mb-15">
                              <h2 class="text-black fontSize">Technology</h2>                              
                            </div>
                            <div class="btn-link-flex">
                              <button type="button" routerLink="custom-development" aria-label="Custom Development">Custom Development</button>
                              <button type="button" routerLink="cloud" aria-label="Cloud">Cloud</button>
                              <button type="button" routerLink="devOps" aria-label="DevOps">DevOps</button>
                              <button type="button" routerLink="secOps" aria-label="SecOps">SecOps</button>
                              <button type="button" routerLink="salesforce-practice" aria-label="Salesforce">Salesforce</button>
                              <button type="button" routerLink="service-now" aria-label="ServiceNow">ServiceNow</button>
                              <button type="button" routerLink="mobility" aria-label="">Mobility</button>
                            </div>
                          </div>
                        </div>
                        <div class="items-col-4 titleFlex">
                          <!-- <p class="nav-large-text">"Transform Your Digital <span>Business Today</span>"</p> -->
                        </div>
                      </div>
                    </div>
                    <!-- <span class="backdrop"></span> -->
                  </li>
                </ul>
                <!-- ---large mene end here--- -->
              </li>

              <li class="dropDown" (mouseenter)="openSubMenu($event)" (mouseleave)="closeSubMenu($event)"><a
                  href="javascript:void(0)" role="button" [ngClass]="{active: selectedMenuIndex==2}">Technology</a>
                <!-- ---large mene beginning here--- -->
                <ul>
                  <li class="circleBg">
                    <div class="menu-lg-container" (click)="onSubMenuClick(2)">
                      <div class="md-link-wp">
                        <div class="mb-15">
                          <a routerLink="case-studies" class="link-md">See All Case Studies</a>
                        </div>
                        <div class="btn-link-flex">
                          <button type="button" routerLink="/case-studies/mortgage-business-digital-transformation"
                            aria-label="Business Digital Transformation">Business Digital Transformation</button>
                          <button type="button" routerLink="/case-studies/finance-digital-transformation" aria-label="Finance BDT">Finance BDT</button>
                          <button type="button" routerLink="/case-studies/cloud-transformation" aria-label="Cloud Transformation">Cloud
                            Transformation</button>
                          <button type="button" routerLink="/case-studies/application-management-service"
                            aria-label="Application Management Service">Application Management Service</button>
                          <button type="button" routerLink="/case-studies/cloud-support" aria-label="Cloud Support">Cloud
                            Support</button>
                          <button type="button" routerLink="/case-studies/cyber-security" aria-label="Cyber Security">Cyber
                            Security</button>
                          <button type="button" routerLink="/case-studies/bdt-customer-journey" aria-label="BDT Customer Journey">BDT Customer
                            Journey</button>
                          <!-- <button type="button" routerLink="/case-studies/fintech-staff-augmentation" aria-label="Staff Augmentation">Staff Augmentation</button>
                          <button type="button" routerLink="/case-studies/tech-consulting-underwriting" aria-label="Tech Consulting Underwriting">Tech Consulting Underwriting</button>
                          <button type="button" routerLink="/case-studies/customer-experience" aria-label="Customer Experience">Customer Experience</button> -->
                        </div>
                      </div>

                      <div class="row-flex menuLink mb-15">
                        <div class="items-col-8">
                          <a routerLink="custom-development">
                            <div class="nav-card techMenuBg1">
                              <div class="">
                                <h2>Custom Development</h2>
                                <p>Our comprehensive suite of services, where we transform ideas into digital reality.
                                  We specialize in three key...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="cloud">
                            <div class="nav-card techMenuBg4">
                              <div class="">
                                <h2>Cloud</h2>
                                <p>In today's rapidly evolving digital landscape, harnessing the potential of cloud
                                  computing is essential...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="devOps">
                            <div class="nav-card techMenuBg5">
                              <div class="">
                                <h2>DevOps</h2>
                                <p>DevOps is more than just a buzzword; it's a transformative approach that accelerates
                                  time-to-market...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="secOps">
                            <div class="nav-card techMenuBg6">
                              <div class="">
                                <h2>SecOps</h2>
                                <p>In today's digital landscape, the integration of security and operations is not just
                                  a necessity...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3 titleFlex">
                          <p class="nav-large-text">"Unleashing the Potential: <span>Empowering the Future through
                              Technology</span>"</p>
                        </div>

                      </div>

                    </div>
                    <!-- <span class="backdrop"></span> -->
                  </li>

                </ul>
                <!-- ---large mene end here--- -->
              </li>

              <li class="dropDown" (mouseenter)="openSubMenu($event)" (mouseleave)="closeSubMenu($event)">
                <a href="javascript:void(0)" role="button" [ngClass]="{active: selectedMenuIndex==3}">About</a>
                <!-- ---large mene beginning here--- -->
                <ul>
                  <li class="circleBg">
                    <div class="menu-lg-container">
                      <div class="row-flex menuLink mb-25" (click)="onSubMenuClick(3)">
                        <div class="items-col-8">
                          <a routerLink="who-we-are">
                            <div class="nav-card aboutMenuBg1">
                              <div class="">
                                <h2>Who We Are</h2>
                                <p>We specialize in Organizational Development, with a rich pedigree in Information
                                  Technology. Our Goal is to help our customers embrace the change with least disruption
                                  and achieve exponential growth...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="our-values">
                            <div class="nav-card aboutMenuBg2">
                              <div class="">
                                <h2>Our Values</h2>
                                <p>We believe that a vibrant company culture, which supports and nurtures our employees,
                                  is key to our success...</p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div class="items-col-3">
                          <a routerLink="esg">
                            <div class="nav-card aboutMenuBg3">
                              <div class="">
                                <h2>ESG</h2>
                                <p>We are dedicated to creating value for all stakeholders while making a positive
                                  impact on the world...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="career">
                            <div class="nav-card aboutMenuBg4">
                              <div class="">
                                <h2>Career</h2>
                                <p>As a consulting firm, we strongly believe in importance of developing individuals,
                                  both our staffs and customers...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="blogs">
                            <div class="nav-card aboutMenuBg5">
                              <div class="">
                                <h2>Blogs</h2>
                                <p>Exploring the Cutting-Edge Innovations in Software Technology...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- <span class="backdrop"></span> -->
                  </li>

                </ul>
                <!-- ---large mene end here--- -->
              </li>


              <li><a name="nameId" routerLink="contact-us" routerLinkActive="active" (click)="onSubMenuClick(4)"
                  [ngClass]="{active: selectedMenuIndex==4}">Contact</a></li>
            </ul>
            <button type="button" (click)="sendToCal()" class="btn btnOrange" aria-label="Get In Touch">
              <span class="webIcon">Get In Touch</span>
              <img src="assets/images/icons/arrowRight-white-icon.svg" class="webIcon" alt="Arrow Icon" width="24"
                height="18">
              <img src="assets/images/icons/meeting-icon.svg" class="mobileIcon" alt="Meeting Icon - Skillikz" width="42"
                height="42">
            </button>
            <div class="menuBtnIcon">
              <button type="button" aria-label="Mobile menu icon" (click)="showMenu()">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.148" height="28" viewBox="0 0 31.148 28">
                  <path id="Union_15" data-name="Union 15"
                    d="M16.648,28V25h14.5v3ZM0,16V13H31.148v3ZM16.648,3V0h14.5V3Z" fill="#0f1b21" />
                </svg>
                <span>MENU</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</header>
<!--Header html end here-->


<!-- Navigation for mobile beginning here -->
<div class="mobileMenuList">
  <div class="menu-header">
    <a routerLink="/">
      <img src="assets/images/skillikz-logo.svg" alt="Skillikz logo" width="158" height="46">
    </a>
    <button type="button" aria-label="Close" (click)="closeMenu()" title="Close">
      <img src="assets/images/icons/cross-icon.svg" alt="Close icon" width="24" height="24">
    </button>
  </div>

  <div class="menu-list">
    <ul (click)="showDropdownMenu($event)">
      <!-- <li><a routerLink="/" (click)="closeAll()">Home</a></li> -->
      <li class="subNav"><a href="javascript:void(0)" role="button">Services</a>
        <ul>
          <li><a routerLink="product-engineering" (click)="closeMenu()">Product Engineering</a></li>
          <li><a routerLink="business-digital-transformation" (click)="closeMenu()">Business Digital Transformation</a></li>
          <li><a routerLink="technology-consulting" (click)="closeMenu()">Consulting</a></li>
          <li><a routerLink="staff-augmentation" (click)="closeMenu()">Staff Augmentation</a></li>
          <li><a routerLink="learning-services" (click)="closeMenu()">Learning Services</a></li>

        </ul>
      </li>
      <li class="subNav"><a href="javascript:void(0)" role="button">Technology</a>
        <ul>
          <li><a routerLink="custom-development" (click)="closeMenu()">Custom Development</a></li>
          <li><a routerLink="cloud" (click)="closeMenu()">Cloud</a></li>
          <li><a routerLink="devOps" (click)="closeMenu()">DevOps</a></li>
          <li><a routerLink="secOps" (click)="closeMenu()">SecOps</a></li>
          <!--           
          <li><a routerLink="web-development" (click)="closeAll()">Frontend</a></li>
          <li><a routerLink="mobility" (click)="closeAll()">Mobility</a></li>
         
         
          <li><a routerLink="salesforce-practice" (click)="closeAll()">Salesforce</a></li>
          <li><a routerLink="service-now" (click)="closeAll()">ServiceNow</a></li>
          <li><a routerLink="adobe-experience-manager" (click)="closeAll()">AEM</a></li>
    
          <li><a name="nameId" routerLink="cloud" routerLinkActive="active" #rla4="routerLinkActive">Cloud</a></li>
          <li><a name="nameId" routerLink="devOps" routerLinkActive="active" #rla5="routerLinkActive">DevOps</a></li>
          <li><a name="nameId" routerLinkActive="active" #rla6="routerLinkActive">SecOps</a></li> -->
        </ul>
      </li>
      <li class="subNav"><a href="javascript:void(0)" role="button">About</a>
        <ul>
          <li><a routerLink="who-we-are" (click)="closeMenu()">Who We Are</a></li>
          <li><a routerLink="our-values" (click)="closeMenu()">Our Values</a></li>
          <li><a routerLink="career" (click)="closeMenu()">Career</a></li>
          <li><a routerLink="blogs" (click)="closeMenu()">Blogs</a></li>
          <li><a routerLink="esg" (click)="closeMenu()">ESG</a></li>
        </ul>
      </li>
      <li><a routerLink="contact-us" (click)="closeMenu()">Contact</a></li>
    </ul>

  </div>


  <div class="items-footer">
    <div class="btnWrapper">
      <button type="button" class="btn btn-orange" (click)="sendToCal()" aria-label="Get In Touch">
        <span>Get In Touch</span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
    <h3>Follow Us On</h3>
    <div class="share-icons mb-20">
      <a href="https://www.linkedin.com/company/skillikz/" target="_new">
        <img src="assets/images/icons/linkedin-blue-icon.svg" title="Click here" alt="Linkedin Icon - Skillikz" width="26"
          height="26">
      </a>
      <a href="https://twitter.com/skillikz" target="_new">
        <img src="assets/images/icons/twitter-icon.svg" title="Click here" alt="Twitter Icon - Skillikz" width="26" height="26">
      </a>
      <a href="https://www.pinterest.co.uk/shwetasharma0081/" target="_new">
        <img src="assets/images/icons/pinterest-icon.svg" title="Click here" alt="Pinterest Icon - Skillikz" width="26"
          height="26">
      </a>
      <a href="https://www.facebook.com/skillikz" target="_new">
        <img src="assets/images/icons/facebook-blue-icon.svg" title="Click here" alt="Facebook Icon - Skillikz" width="26"
          height="26">
      </a>
      <a href="https://www.youtube.com/@skillikz2337" target="_new">
        <img src="assets/images/icons/youtube-icon.svg" title="Click here" alt="YouTube Icon - Skillikz" width="26" height="26">
      </a>
      <a href="https://www.instagram.com/shwetasharmaskillikz/" target="_new">
        <img src="assets/images/icons/instagram-icon.svg" title="Click here" alt="Instagram Icon - Skillikz" width="26"
          height="26">
      </a>
    </div>

    <div class="mobileFooterMenu">
      <p>&copy; Skillikz Limited 2023 All Right Reserved. <span>|</span> </p>
      <div class="itemList">
        <a routerLink="privacy-policy">Privacy Policy</a> |
        <!-- <a routerLink="terms-of-use">Terms of Use</a> |  -->
        <a routerLink="cookies-policy">Cookies Policy</a>
      </div>
    </div>

  </div>
</div>
<!-- Navigation for mobile end here -->
<div class="siteBackdrop" (click)="closeAll()"></div>