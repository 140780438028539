import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.css']
})

export class CookiesPolicyComponent extends BaseClass implements OnInit{
    constructor() {
      super()
     }
  
    ngOnInit(): void {
      init(this);
    }
  
  }
  
  function init(context: CookiesPolicyComponent) {  
    context.title = 'Cookies Policy - SKILLIKZ';
    context.description = "Review Skillikz's conditions for using our website and services.";
    context.keywords = 'Skillikz Cookie policy';
    context.seoTags();
  }