import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details50',
  templateUrl: './blogs-details50.component.html',
  styleUrls: ['./blogs-details50.component.css']
})
export class BlogsDetails50Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails50Component) {  
  context.title = 'Software Development Outsourcing: Best Practices';
  context.description = 'Explore the benefits and best practices for outsourcing software development to drive innovation and efficiency.';
  context.keywords = 'Software Development, Outsourcing';
  context.seoTags();
}
