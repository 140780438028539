import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details49',
  templateUrl: './blogs-details49.component.html',
  styleUrls: ['./blogs-details49.component.css']
})
export class BlogsDetails49Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails49Component) {  
  context.title = 'Enablers of Business Digital Transformation in SMEs';
  context.description = 'Learn about the key enablers helping small and medium-sized enterprises (SMEs) successfully navigate their digital transformation journey.';
  context.keywords = 'Business Digital Transformation, SMEs';
  context.seoTags();
}