import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details48',
  templateUrl: './blogs-details48.component.html',
  styleUrls: ['./blogs-details48.component.css']
})
export class BlogsDetails48Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails48Component) {  
  context.title = "Business Automation in 2023: A Necessity";
  context.description = "Explore why business automation has become essential in 2023 for companies aiming to remain competitive in an evolving landscape.";
  context.keywords = 'Business Automation, 2023';
  context.seoTags();
}
