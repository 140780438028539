import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details30',
  templateUrl: './blogs-details30.component.html',
  styleUrls: ['./blogs-details30.component.css']
})
export class BlogsDetails30Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails30Component) {  
  context.title = 'Best Practices in Business Digital Transformation';
  context.description = 'Discover the best practices for achieving successful business digital transformation.';
  context.keywords = 'Business Digital Transformation';
  context.seoTags();
}
