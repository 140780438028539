import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details32',
  templateUrl: './blogs-details32.component.html',
  styleUrls: ['./blogs-details32.component.css']
})
export class BlogsDetails32Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails32Component) {  
  context.title = 'Effective use of data science in financial underwriting';
  context.description = 'Data Science in Financial Underwriting, Financial Underwriting';
  context.keywords = 'Discover how data science is transforming financial underwriting. This comparative analysis highlights the benefits.';
  context.seoTags();
}
