import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details22',
  templateUrl: './blogs-details22.component.html',
  styleUrls: ['./blogs-details22.component.css']
})
export class BlogsDetails22Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails22Component) {  
  context.title = 'Data-Driven Decision Making in Business';
  context.description = 'Learn how businesses use data-driven decision-making to enhance operations and gain a competitive advantage.';
  context.keywords = 'Data-Driven Decisions, Business';
  context.seoTags();
}
