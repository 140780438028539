import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'customdate'
})
export class CustomDatePipe implements PipeTransform {
    format: string = "Do MMMM, YYYY";
    transform(value: any, args?: any): any {
        if (args) {
            this.format = args;
        }
        return moment.utc(value, "DD-MMMM-yyyy").local().format(this.format);
    }

}