<!-- <button (click)="gotoTopFunction()" class="animate" id="gototop-btn">
  <img src="assets/images/icons/arrow-up.svg" alt="Scroll to Top">
  <span>Scroll to Top</span>
</button> -->

<button class="animate" id="gototop-btn" routerLink="contact-us">
  <img src="assets/images/icons/contact-icon.svg" alt="Contact Icon - Skillikz">
  <span>Contact</span>
</button>


<!-- <img src="assets/images/icons/call-icon.svg" alt="Call Icon - Skillikz" width="24" -->



<!--footer-starts-->

<footer>
  <div class="container-main scroll-elem fade-in-bottom">

    <div class="footerLeftContent">
      <div class="onlyForTab">
        <h2>Contact Us</h2>
        <div class="mb-30">
          <div class="action-wrapper">
            <a href="tel:4401268533827"><img src="assets/images/icons/call-icon.svg" alt="Call Icon - Skillikz" width="24"
                height="24"><span>(+44) 01268 533 827</span></a>
            <a href="javascript:void(0)" role="button" title="Copy Phone Number"
              (click)="copyToClipboard('4401268533827')"><img src="assets/images/icons/copy-icon.svg" alt="Copy icon"
                width="24" height="24"></a>
          </div>
          <div class="action-wrapper">
            <a href="mailto:info@skillikz.com"><img src="assets/images/icons/mail-icon.svg" alt="Mail Icon - Skillikz"
                width="24" height="24"><span>info&#64;skillikz.com</span></a>
            <a href="javascript:void(0)" role="button" title="Copy E-Mail"
              (click)="copyToClipboard('info@skillikz.com')"><img src="assets/images/icons/copy-icon.svg"
                alt="Copy icon" width="24" height="24"></a>
          </div>
          <!-- <div *ngIf="showCopied" class="copy-message mb-20">
          Copied to clipboard!
        </div> -->

          <button type="button" class="btn btn-orange" (click)="sendToCal()" aria-label="Quick Help">
            <span>Quick Chat</span>
            <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
          </button>


        </div>

        <div class="social-wp">
          <h2>Follow Us On</h2>
          <div class="share-icons mb-30">
            <a href="https://www.linkedin.com/company/skillikz/" target="_new">
              <img src="assets/images/icons/linkedin-blue-icon.svg" title="Click here" alt="Linkedin Icon - Skillikz" width="26"
                height="26">
              <span>Linkedin</span>
            </a>
            <a href="https://twitter.com/skillikz" target="_new">
              <img src="assets/images/icons/twitter-icon.svg" title="Click here" alt="Twitter Icon - Skillikz" width="26"
                height="26">
              <span>Twitter</span>
            </a>
            <a href="https://www.pinterest.co.uk/shwetasharma0081/" target="_new">
              <img src="assets/images/icons/pinterest-icon.svg" title="Click here" alt="Pinterest Icon - Skillikz" width="26"
                height="26">
              <span>Pinterest</span>
            </a>
          </div>

          <div class="share-icons">
            <a href="https://www.facebook.com/skillikz" target="_new">
              <img src="assets/images/icons/facebook-blue-icon.svg" title="Click here" alt="Facebook Icon - Skillikz" width="26"
                height="26">
              <span>Facebook</span>
            </a>
            <a href="https://www.youtube.com/@skillikz2337" target="_new">
              <img src="assets/images/icons/youtube-icon.svg" title="Click here" alt="YouTube Icon - Skillikz" width="26"
                height="26">
              <span>YouTube</span>
            </a>
            <a href="https://www.instagram.com/shwetasharmaskillikz/" target="_new">
              <img src="assets/images/icons/instagram-icon.svg" title="Click here" alt="Instagram Icon - Skillikz" width="26"
                height="26">
              <span>Instagram</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footerRightContent">
      <div class="row-flex">
        <div class="items-col-4">
          <h2>Main</h2>
          <ul class="mainNav">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="product-engineering">Product Engineering</a></li>
            <li><a routerLink="business-digital-transformation">Business Digital Transformation</a></li>
            <li><a routerLink="technology-consulting">Consulting</a></li>
            <li><a routerLink="staff-augmentation">Staff Augmentation</a></li>
            <li><a routerLink="learning-services">Learning Services</a></li>
            <li><a routerLink="case-studies">Case Studies</a></li>
          </ul>
        </div>
        <div class="items-col-4">
          <h2>Technology</h2>
          <ul class="mainNav">
            <li><a routerLink="custom-development">Custom Development</a></li>
            <!-- <li><a routerLink="backend">Backend</a></li>
            <li><a routerLink="web-development">Web Development</a></li> -->
            <li><a routerLink="cloud">Cloud</a></li>
            <li><a routerLink="devOps">DevOps</a></li>
            <li><a routerLink="secOps">SecOps</a></li>
            <li><a routerLink="salesforce-practice">Salesforce</a></li>
            <li><a routerLink="service-now">ServiceNow</a></li>
            <li><a routerLink="mobility">Mobility</a></li>
            <!-- <li><a routerLink="adobe-experience-manager">AEM</a></li> -->
          </ul>
        </div>
        <div class="items-col-4">
          <h2>About</h2>

          <ul class="mainNav">
            <!-- <li><a href="#">Who We Are</a></li>
            <li><a href="#">Our Values</a></li> -->
            <li><a routerLink="who-we-are">Who we are</a></li>
            <li><a routerLink="our-values">Our Values</a></li>
            <li><a routerLink="esg">ESG</a></li>
            <li><a routerLink="blogs">Blogs</a></li>
          </ul>
        </div>
        <div class="items-col-4">
          <div class="">
            <h2>Career</h2>
            <ul class="mainNav">
              <li><a routerLink="career">Career</a></li>
              <li><a routerLink="all-job-openings">All Job Openings</a></li>
            </ul>
          </div>
          <!-- <div class="">
                    <button type="button" class="btn btn-orange" aria-label="Contact Us" routerLink="contact-us">
                      <span>Contact Us</span>
                      <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
                    </button>
                  </div> -->
        </div>
      </div>
    </div>

  </div>
  <div class="footerBg"></div>
</footer>

<section class="footerBottomSection">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-2 copyRightText">
        <p>&copy; Skillikz Limited {{currentYear}} All Rights Reserved.</p>
      </div>
      <div class="items-col-2 bottomNav">
        <ul>
          <!-- <li><a href="#">Sitemap</a></li> -->
          <li><a routerLink="privacy-policy">Privacy Policy</a></li>
          <li><a routerLink="terms-of-use">Terms of Use</a></li>
          <li><a routerLink="cookies-policy">Cookies Policy</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>


<app-popup-modal></app-popup-modal>
<app-cookies-consent></app-cookies-consent>