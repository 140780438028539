import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details16',
  templateUrl: './blogs-details16.component.html',
  styleUrls: ['./blogs-details16.component.css']
})
export class BlogsDetails16Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails16Component) {  
  context.title = 'New Trends in IT Product Engineering';
  context.description = 'Stay ahead of the curve with the latest trends in IT product engineering and development.';
  context.keywords = 'IT Product Engineering, Trends';
  context.seoTags();
}
