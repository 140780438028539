<div class="slider-main">
  <div class="slideshow-wrapper">
    <div class="itemSlides fade">
      <div class="testimony-flex">
        <div class="item-img">
          <img src="assets/images/testimony-img-1.webp" alt="Customer Testimonial 1 - Skillikz" width="320" height="245"
            onerror="this.src='assets/images/testimony-img-1.png'">
        </div>
        <div class="item-content">
          <div class="scrollElem">
            <p>"Our collaboration with Skillikz has been outstanding. Their team quickly grasped our requirements and
              delivered
              solutions that enhanced our operations
              significantly. Their deep expertise enabled us to concentrate on our core business without worrying about
              IT
              issues. Through optimising our cloud services
              and reducing our on-premises infrastructure, Skillikz achieved significant cost savings and minimised
              disruptions."</p>
          </div>
          <p><span>Keith Barnes</span> <br> Chief Executive Officer - The Asset Exchange Ltd</p>

        </div>
      </div>
    </div>

    <div class="itemSlides fade">
      <div class="testimony-flex">
        <div class="item-img">
          <img src="assets/images/testimony-img-2.webp" alt="Customer Testimonial 2 - Skillikz" width="320" height="245"
            onerror="this.src='assets/images/testimony-img-2.png'">
        </div>
        <div class="item-content">
          <div class="scrollElem">
            <p>“We had the pleasure of partnering with Skillikz on our various learning initiative, and the experience
              exceeded
              our expectations. From the outset, their team demonstrated a deep understanding of educational
              technologies and a
              keen insight into our unique needs. They provided us with a comprehensive Learning Management System that
              is
              user-friendly, robust, and highly customizable.</p>
            <p>The learning content they created was engaging, interactive, and tailored perfectly to our target
              audience. Their
              ability to translate complex concepts into easily digestible modules made a significant impact on our
              learners’
              engagement and retention rates. Moreover, the support and training they provided ensured that our team
              could manage
              and update the system independently, giving us confidence in our investment.</p>
            <p>We highly recommend Skillikz for any organization looking to enhance their learning and development
              initiatives
              with cutting-edge LMS solutions and high-quality e-learning content. Their professionalism, expertise, and
              commitment to excellence make them a standout partner in the industry.”</p>
          </div>
          <p><span>Jitendra Vaswani</span> <br> Chief Managing Director - Siksha</p>
        </div>
      </div>
    </div>
    <a class="prev" (click)="plusSlides(-1)">❮</a>
    <a class="next" (click)="plusSlides(1)">❯</a>
  </div>

  <div style="text-align:center">
    <span class="dot" (click)="currentSlide(1)"></span>
    <span class="dot" (click)="currentSlide(2)"></span>
    <!-- <span class="dot" (click)="currentSlide(3)"></span>  -->
  </div>

</div>