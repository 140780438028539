import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details38',
  templateUrl: './blogs-details38.component.html',
  styleUrls: ['./blogs-details38.component.css']
})
export class BlogsDetails38Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails38Component) {  
  context.title = 'Latest Trends in Frontend Technologies for Software Development';
  context.description = 'Frontend Development, Frontend Trends, UI/UX Technologies';
  context.keywords = 'Stay updated on the latest trends in frontend technologies shaping software development, including frameworks and tools.';
  context.seoTags();
}
