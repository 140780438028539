import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-learning-services',
  templateUrl: './learning-services.component.html',
  styleUrls: ['./learning-services.component.css']
})
export class LearningServicesComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

  sendToCal(){
    var d = new Date().getFullYear().toString() + '-' + '0' + (new Date().getMonth() + 1).toString().slice(-2);
    const url = 'https://calendly.com/skillikz/30min?back=1&month='+d;
    window.open(url, '_blank');
  }

}
function init(context: LearningServicesComponent) {  
  context.title = 'Skillikz Courses - Expert Training for Your Career';
  context.description = 'Explore a range of professional courses that help you advance in your career with hands-on expertise.';
  context.keywords = 'Skillikz Courses, Training';
  context.seoTags();
}
