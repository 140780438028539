import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details44',
  templateUrl: './blogs-details44.component.html',
  styleUrls: ['./blogs-details44.component.css']
})
export class BlogsDetails44Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails44Component) {  
  context.title = 'Leveraging Digital Transformation Framework for Technology Adoption';
  context.description = "Discover how to leverage a digital transformation framework for effective technology adoption in your organization.";
  context.keywords = 'Digital Transformation Framework, Technology Adoption';
  context.seoTags();
}
