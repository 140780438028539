import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogsDetails1Component } from './views/blogs-pages/blogs-details1/blogs-details1.component';
import { BlogsDetails2Component } from './views/blogs-pages/blogs-details2/blogs-details2.component';
import { BlogsDetails3Component } from './views/blogs-pages/blogs-details3/blogs-details3.component';
import { BlogsDetails4Component } from './views/blogs-pages/blogs-details4/blogs-details4.component';
import { BlogsDetails5Component } from './views/blogs-pages/blogs-details5/blogs-details5.component';
import { BlogsDetails6Component } from './views/blogs-pages/blogs-details6/blogs-details6.component';
import { BlogsDetails7Component } from './views/blogs-pages/blogs-details7/blogs-details7.component';
import { BlogsDetails8Component } from './views/blogs-pages/blogs-details8/blogs-details8.component';
import { BlogsDetails9Component } from './views/blogs-pages/blogs-details9/blogs-details9.component';
import { BlogsDetails10Component } from './views/blogs-pages/blogs-details10/blogs-details10.component';
import { BlogsDetails11Component } from './views/blogs-pages/blogs-details11/blogs-details11.component';
import { BlogsDetails12Component } from './views/blogs-pages/blogs-details12/blogs-details12.component';
import { BlogsDetails13Component } from './views/blogs-pages/blogs-details13/blogs-details13.component';
import { BlogsDetails14Component } from './views/blogs-pages/blogs-details14/blogs-details14.component';
import { BlogsDetails15Component } from './views/blogs-pages/blogs-details15/blogs-details15.component';
import { BlogsDetails16Component } from './views/blogs-pages/blogs-details16/blogs-details16.component';
import { BlogsDetails17Component } from './views/blogs-pages/blogs-details17/blogs-details17.component';
import { BlogsDetails18Component } from './views/blogs-pages/blogs-details18/blogs-details18.component';
import { BlogsDetails19Component } from './views/blogs-pages/blogs-details19/blogs-details19.component';
import { BlogsDetails20Component } from './views/blogs-pages/blogs-details20/blogs-details20.component';
import { BlogsDetails21Component } from './views/blogs-pages/blogs-details21/blogs-details21.component';
import { BlogsDetails22Component } from './views/blogs-pages/blogs-details22/blogs-details22.component';
import { BlogsDetails23Component } from './views/blogs-pages/blogs-details23/blogs-details23.component';
import { BlogsDetails24Component } from './views/blogs-pages/blogs-details24/blogs-details24.component';
import { BlogsDetails25Component } from './views/blogs-pages/blogs-details25/blogs-details25.component';
import { BlogsDetails26Component } from './views/blogs-pages/blogs-details26/blogs-details26.component';
import { BlogsDetails27Component } from './views/blogs-pages/blogs-details27/blogs-details27.component';
import { BlogsDetails28Component } from './views/blogs-pages/blogs-details28/blogs-details28.component';
import { BlogsDetails29Component } from './views/blogs-pages/blogs-details29/blogs-details29.component';
import { BlogsDetails30Component } from './views/blogs-pages/blogs-details30/blogs-details30.component';
import { BlogsDetails31Component } from './views/blogs-pages/blogs-details31/blogs-details31.component';
import { BlogsDetails32Component } from './views/blogs-pages/blogs-details32/blogs-details32.component';
import { BlogsDetails33Component } from './views/blogs-pages/blogs-details33/blogs-details33.component';
import { BlogsDetails34Component } from './views/blogs-pages/blogs-details34/blogs-details34.component';
import { BlogsDetails35Component } from './views/blogs-pages/blogs-details35/blogs-details35.component';
import { BlogsDetails36Component } from './views/blogs-pages/blogs-details36/blogs-details36.component';
import { BlogsDetails37Component } from './views/blogs-pages/blogs-details37/blogs-details37.component';
import { BlogsDetails38Component } from './views/blogs-pages/blogs-details38/blogs-details38.component';
import { BlogsDetails39Component } from './views/blogs-pages/blogs-details39/blogs-details39.component';
import { BlogsDetails40Component } from './views/blogs-pages/blogs-details40/blogs-details40.component';

import { BlogsDetails41Component } from './views/blogs-pages/blogs-details41/blogs-details41.component';
import { BlogsDetails42Component } from './views/blogs-pages/blogs-details42/blogs-details42.component';
import { BlogsDetails43Component } from './views/blogs-pages/blogs-details43/blogs-details43.component';
import { BlogsDetails44Component } from './views/blogs-pages/blogs-details44/blogs-details44.component';
import { BlogsDetails45Component } from './views/blogs-pages/blogs-details45/blogs-details45.component';
import { BlogsDetails46Component } from './views/blogs-pages/blogs-details46/blogs-details46.component';
import { BlogsDetails47Component } from './views/blogs-pages/blogs-details47/blogs-details47.component';
import { BlogsDetails48Component } from './views/blogs-pages/blogs-details48/blogs-details48.component';
import { BlogsDetails49Component } from './views/blogs-pages/blogs-details49/blogs-details49.component';

import { BlogsDetails50Component } from './views/blogs-pages/blogs-details50/blogs-details50.component';
import { BlogsDetails51Component } from './views/blogs-pages/blogs-details51/blogs-details51.component';
import { BlogsDetails52Component } from './views/blogs-pages/blogs-details52/blogs-details52.component';
import { BlogsDetails53Component } from './views/blogs-pages/blogs-details53/blogs-details53.component';
import { BlogsDetails54Component } from './views/blogs-pages/blogs-details54/blogs-details54.component';
import { BlogsDetails55Component } from './views/blogs-pages/blogs-details55/blogs-details55.component';
import { BlogsDetails56Component } from './views/blogs-pages/blogs-details56/blogs-details56.component';
import { BlogsDetails57Component } from './views/blogs-pages/blogs-details57/blogs-details57.component';


import { BlogsComponent } from './views/blogs/blogs.component';
import { BusinessAnalystComponent } from './views/business-analyst/business-analyst.component';
import { AllJobOpeningsComponent } from './views/career-module/all-job-openings/all-job-openings.component';
import { ChaosResiliencyTestEngineerComponent } from './views/chaos-resiliency-test-engineer/chaos-resiliency-test-engineer.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { CustomerServiceAssociateComponent } from './views/customer-service-associate/customer-service-associate.component';
import { DotNetDeveloperComponent } from './views/dot-net-developer/dot-net-developer.component';
import { DotNetTechnicalArchitectComponent } from './views/dot-net-technical-architect/dot-net-technical-architect.component';
import { ELearningDeveloperComponent } from './views/e-learning-developer/e-learning-developer.component';
import { EsgComponent } from './views/esg/esg.component';
import { FinancialComplianceComponent } from './views/financial-compliance/financial-compliance.component';
import { HomeComponent } from './views/home/home.component';
import { HumanResourcesComponent } from './views/human-resources/human-resources.component';
import { JavaDeveloper1Component } from './views/java-developer1/java-developer1.component';
import { JavaDeveloper2Component } from './views/java-developer2/java-developer2.component';
import { JobDetailsComponent } from './views/job-details/job-details.component';
import { OurValuesComponent } from './views/our-values/our-values.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { BusinessDigitalTransformationComponent } from './views/services-module/business-digital-transformation/business-digital-transformation.component';
import { LearningServicesComponent } from './views/services-module/learning-services/learning-services.component';
import { ProductEngineeringComponent } from './views/services-module/product-engineering/product-engineering.component';
import { SalesforcePracticeComponent } from './views/services-module/salesforce-practice/salesforce-practice.component';
import { TechnologyConsultingComponent } from './views/services-module/technology-consulting/technology-consulting.component';
import { BackendComponent } from './views/technology-module/backend/backend.component';
import { FrontendComponent } from './views/technology-module/frontend/frontend.component';
import { MobilityComponent } from './views/technology-module/mobility/mobility.component';
import { TermsConditionsComponent } from './views/terms-conditions/terms-conditions.component';
import { TraineeDataAnalystComponent } from './views/trainee-data-analyst/trainee-data-analyst.component';
import { TraineeProjectManagerComponent } from './views/trainee-project-manager/trainee-project-manager.component';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { CookiesPolicyComponent } from './views/cookies-policy/cookies-policy.component';
import { AdobeExperienceManagerComponent } from './views/adobe-experience-manager/adobe-experience-manager.component';
import { ServiceNowComponent } from './views/technology-module/service-now/service-now.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { HealthAndSafetyComponent } from './views/lms-pages/health-and-safety/health-and-safety.component';
import { HealthCareComponent } from './views/lms-pages/health-care/health-care.component';
import { InformationSecurityComponent } from './views/lms-pages/information-security/information-security.component';
import { LeadershipAndManagementComponent } from './views/lms-pages/leadership-and-management/leadership-and-management.component';
import { CloudComponent } from './views/technology-module/cloud/cloud.component';
import { CaseStudiesComponent } from './views/services-module/case-studies/case-studies.component';
import { CaseStudy1Component } from './views/services-module/case-study1/case-study1.component';
import { CaseStudy2Component } from './views/services-module/case-study2/case-study2.component';
import { CaseStudy3Component } from './views/services-module/case-study3/case-study3.component';
import { CaseStudy4Component } from './views/services-module/case-study4/case-study4.component';
import { CaseStudy5Component } from './views/services-module/case-study5/case-study5.component';
import { CaseStudy6Component } from './views/services-module/case-study6/case-study6.component';
import { CaseStudy7Component } from './views/services-module/case-study7/case-study7.component';
import { CaseStudy8Component } from './views/services-module/case-study8/case-study8.component';
import { CaseStudy9Component } from './views/services-module/case-study9/case-study9.component';
import { StaffAugmentationComponent } from './views/services-module/staff-augmentation/staff-augmentation.component';
import { CaseStudy10Component } from './views/services-module/case-study10/case-study10.component';
import { SecOpsComponent } from './views/technology-module/sec-ops/sec-ops.component';
import { BigDataConsultantComponent } from './views/big-data-consultant/big-data-consultant.component';
import { DevOpsComponent } from './views/technology-module/dev-ops/dev-ops.component';
import { CustomDevelopmentComponent } from './views/technology-module/custom-development/custom-development.component';
import { CareerComponent } from './views/career/career.component';
import { WhoWeAreComponent } from './views/who-we-are/who-we-are.component';
import { GolangSoftwareEngineerComponent } from './views/golang-software-engineer/golang-software-engineer.component';
import { UiEngineersReactjsNodejsComponent } from './views/ui-engineers-reactjs-nodejs/ui-engineers-reactjs-nodejs.component';
import { SrEngineerDevopsComponent } from './views/sr-engineer-devops/sr-engineer-devops.component';
import { ProductOwnerUkComponent } from './views/product-owner-uk/product-owner-uk.component';
import { QuallityEngineerItComponent } from './views/quallity-engineer-it/quallity-engineer-it.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "who-we-are", component: WhoWeAreComponent },
  { path: "our-values", component: OurValuesComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "backend", component: BackendComponent },
  { path: "web-development", component: FrontendComponent },
  { path: "mobility", component: MobilityComponent },
  { path: "devOps", component: DevOpsComponent },
  { path: "secOps", component: SecOpsComponent },
  { path: "product-engineering", component: ProductEngineeringComponent },
  { path: "business-digital-transformation", component: BusinessDigitalTransformationComponent },
  { path: "technology-consulting", component: TechnologyConsultingComponent },
  { path: "salesforce-practice", component: SalesforcePracticeComponent },
  
  { path: "job-details", component: JobDetailsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-of-use-lms", component: TermsConditionsComponent },
  { path: "esg", component: EsgComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "cookies-policy", component: CookiesPolicyComponent },
  { path: "service-now", component: ServiceNowComponent },
  { path: "custom-development", component: CustomDevelopmentComponent },
  { path: "career", component: CareerComponent },
  
  { path: "all-job-openings", component: AllJobOpeningsComponent },
  { path:"all-job-openings/quality-engineer", component: QuallityEngineerItComponent },
  { path: "all-job-openings/golang-software-engineer", component: GolangSoftwareEngineerComponent },
  { path: "all-job-openings/ui-engineers-reactjs-nodejs", component: UiEngineersReactjsNodejsComponent },
  { path: "all-job-openings/sr-engineer-devops", component: SrEngineerDevopsComponent },
  { path: "all-job-openings/product-owner-uk", component: ProductOwnerUkComponent },
  { path: "all-job-openings/big-data-consultant", component: BigDataConsultantComponent },
  { path: "all-job-openings/dot-net-developer", component: DotNetDeveloperComponent },
  { path: "all-job-openings/sr-java-developer", component: JavaDeveloper1Component },
  { path: "all-job-openings/java-developer", component: JavaDeveloper2Component },
  { path: "all-job-openings/chaos-resiliency-test-engineer", component: ChaosResiliencyTestEngineerComponent },
  { path: "all-job-openings/business-analyst", component: BusinessAnalystComponent },
  { path: "all-job-openings/dot-net-technical-architect", component: DotNetTechnicalArchitectComponent },
  { path: "all-job-openings/trainee-project-manager", component: TraineeProjectManagerComponent },
  { path: "all-job-openings/trainee-data-analyst", component: TraineeDataAnalystComponent },
  { path: "all-job-openings/customer-service-associate", component: CustomerServiceAssociateComponent },
  { path: "all-job-openings/e-learning-developer", component: ELearningDeveloperComponent },

  { path: "adobe-experience-manager", component: AdobeExperienceManagerComponent },
  
  { path: "blogs", component: BlogsComponent },
  { path: "blogs/new-trends-in-business-digital-transformation", component: BlogsDetails1Component },
  { path: "blogs/stages-in-business-digital-transformation", component: BlogsDetails2Component },
  { path: "blogs/digitisation-vs-digitalisation-vs-digital-transformation", component: BlogsDetails3Component },
  { path: "blogs/new-trends-in-cloud-computing", component: BlogsDetails4Component },
  { path: "blogs/application-of-hybrid-cloud-in-business-processes", component: BlogsDetails5Component },
  { path: "blogs/application-of-edge-computing-in-businesses", component: BlogsDetails6Component },
  { path: "blogs/new-trends-in-serverless-computing-in-businesses", component: BlogsDetails7Component },
  { path: "blogs/application-of-multi-cloud-computing-in-businesses", component: BlogsDetails8Component },
  { path: "blogs/what-is-ai-and-what-are-its-business-applications", component: BlogsDetails9Component },
  { path: "blogs/business-applications-of-machine-learning", component: BlogsDetails10Component },
  { path: "blogs/strategies-in-business-process-improvement", component: BlogsDetails11Component },
  { path: "blogs/combating-financial-fraud-using-ai-and-ml", component: BlogsDetails12Component },
  { path: "blogs/ai-powered-compliance-systems", component: BlogsDetails13Component },
  { path: "blogs/emerging-trends-in-erp-systems", component: BlogsDetails14Component },
  { path: "blogs/importance-of-erp-systems-in-business", component: BlogsDetails15Component },
  { path: "blogs/new-trends-in-product-engineering", component: BlogsDetails16Component },
  { path: "blogs/cloud-native-development-in-product-engineering", component: BlogsDetails17Component },
  { path: "blogs/devops-and-agile-development", component: BlogsDetails18Component },
  { path: "blogs/new-trends-in-it-product-experience-and-design", component: BlogsDetails19Component },
  { path: "blogs/latest-practices-in-it-product-development-and-testing", component: BlogsDetails20Component },
  { path: "blogs/design-thinking-process-in-it-product-development", component: BlogsDetails21Component },
  { path: "blogs/data-driven-decision-making-in-business", component: BlogsDetails22Component },
  { path: "blogs/cloud-modernisation-strategy", component: BlogsDetails23Component },
  { path: "blogs/architecture-design-in-it-product-development", component: BlogsDetails24Component },
  { path: "blogs/pros-and-cons-of-cloud-native-architecture-in-software-development", component: BlogsDetails25Component },
  { path: "blogs/benefits-and-challenges-of-microservice-architecture-in-software-development", component: BlogsDetails26Component },
  { path: "blogs/event-driven-architecture-in-software-development", component: BlogsDetails27Component },
  { path: "blogs/serverless-architecture-in-software-development", component: BlogsDetails28Component },
  { path: "blogs/data-driven-architecture-design-in-software-development-benefits-and-challenges", component: BlogsDetails29Component },
  { path: "blogs/best-practices-in-business-digital-transformation", component: BlogsDetails30Component },
  { path: "blogs/good-practices-in-it-product-development", component: BlogsDetails31Component },
  { path: "blogs/effective-use-of-data-science-in-financial-underwriting", component: BlogsDetails32Component },
  { path: "blogs/latest-trends-in-financial-software-development", component: BlogsDetails33Component },
  { path: "blogs/robotic-process-automation", component: BlogsDetails34Component },
  { path: "blogs/hyperautomation-in-business", component: BlogsDetails35Component },
  { path: "blogs/blockchain-technology", component: BlogsDetails36Component },
  { path: "blogs/open-banking-and-financial-technology", component: BlogsDetails37Component },
  { path: "blogs/latest-trends-in-frontend-technologies-in-software-development", component: BlogsDetails38Component },
  { path: "blogs/latest-trends-in-backend-technologies-in-software-development", component: BlogsDetails39Component },
  { path: "blogs/emerging-trends-in-bigdata-and-analytics", component: BlogsDetails40Component },

  { path: "blogs/navigating-regulatory-compliance-the-challenges-businesses-face-and-the-vitality-of-training-employees-on-compliance", component: BlogsDetails41Component },
  { path: "blogs/compliance-culture-tech-industry", component: BlogsDetails42Component },
  { path: "blogs/digital-maturity-in-uk-adult-social-care-bridging-the-technological-divide", component: BlogsDetails43Component },
  { path: "blogs/leveraging-digital-transformation-framework-for-effective-technology-adoption", component: BlogsDetails44Component },
  { path: "blogs/challenges-financial-compliance-market-review", component: BlogsDetails45Component },
  { path: "blogs/rise-progressive-web-apps-pwas-ecommerce", component: BlogsDetails46Component }, 
  { path: "blogs/digital-immune-system-future-cybersecurity", component: BlogsDetails47Component }, 
  { path: "blogs/business-automation-in-2023-no-longer-a-nice-to-have-but-a-necessity", component: BlogsDetails48Component },  
  { path: "blogs/enablers-of-business-digital-transformation-journey-in-smes", component: BlogsDetails49Component },  

  { path: "blogs/software-development-team-as-service", component: BlogsDetails50Component}, 
  { path: "blogs/technology-adoption-a-sign-of-top-performing-companies", component: BlogsDetails51Component}, 
  { path: "blogs/overcoming-challenges-digital-transformation", component: BlogsDetails52Component}, 
  { path: "blogs/changing-landscape-competitive-advantage", component: BlogsDetails53Component}, 
  { path: "blogs/integral-role-of-people-digital-transformation", component: BlogsDetails54Component}, 
  { path: "blogs/steering-digital-transformation-with-purpose-driven-leadership", component: BlogsDetails55Component}, 
  { path: "blogs/static-to-strategic-a-digital-transformation-guide", component: BlogsDetails56Component}, 
  { path: "blogs/new-technology-investment-paradigm-roi-2024", component: BlogsDetails57Component},  
  
  
  { path:"learning-services", component: LearningServicesComponent },
  { path:"learning-services/health-and-safety", component: HealthAndSafetyComponent },
  { path:"learning-services/health-care", component: HealthCareComponent },
  { path:"learning-services/financial-compliance", component: FinancialComplianceComponent },  
  { path:"learning-services/information-security", component: InformationSecurityComponent },
  { path:"learning-services/human-resources", component: HumanResourcesComponent },
  { path:"learning-services/leadership-and-management", component: LeadershipAndManagementComponent },

  { path:"cloud", component: CloudComponent },
  { path:"staff-augmentation", component: StaffAugmentationComponent },
  
  { path:"case-studies", component: CaseStudiesComponent },
  { path:"case-studies/mortgage-business-digital-transformation", component: CaseStudy1Component },
  { path:"case-studies/finance-digital-transformation", component: CaseStudy2Component },
  { path:"case-studies/cloud-transformation", component: CaseStudy3Component },
  { path:"case-studies/application-management-service", component: CaseStudy4Component },
  { path:"case-studies/cloud-support", component: CaseStudy5Component },
  { path:"case-studies/cyber-security", component: CaseStudy6Component },
  { path:"case-studies/bdt-customer-journey", component: CaseStudy7Component },
  { path:"case-studies/fintech-staff-augmentation", component: CaseStudy8Component },
  { path:"case-studies/tech-consulting-underwriting", component: CaseStudy9Component },
  { path:"case-studies/customer-experience", component: CaseStudy10Component },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
