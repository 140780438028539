import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details31',
  templateUrl: './blogs-details31.component.html',
  styleUrls: ['./blogs-details31.component.css']
})
export class BlogsDetails31Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails31Component) {  
  context.title = 'Good Practices in IT Product Development';
  context.description = 'Learn about the good practices that can drive successful IT product development and innovation.';
  context.keywords = 'IT Product Development, Best Practices';
  context.seoTags();
}
