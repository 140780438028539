import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BlogsModel } from 'src/app/modals/blogs.modal';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent extends BaseClass implements OnInit {

  constructor(private _httpClient: HttpClient) { super() }
  blogs: BlogsModel[] = [];
  tempBlogs: BlogsModel[] = [];

  ngOnInit(): void {
    init(this);
    this._httpClient.get("assets/json/blogs.json")
      .subscribe((response: any) => {
        this.blogs = response;
        this.tempBlogs = JSON.parse(JSON.stringify(response));
      })
  }
  onSearch(event) {
    let value: string = event.target.value;
    if (value && value.length >= 2) {
      value = value.toLocaleLowerCase();
      this.blogs = this.tempBlogs.filter(e => e.description.toLocaleLowerCase().includes(value) || e.title.toLocaleLowerCase().includes(value))
    }
    if (!value) {
      this.blogs = JSON.parse(JSON.stringify(this.tempBlogs))
    }
  }

}

function init(context: BlogsComponent) {
  context.title = 'Skillikz Blog - Insights, Tips & Tutorials';
  context.description = 'Stay updated with the latest articles, tips, and tutorials to improve your professional skills and knowledge.';
  context.keywords = 'Skillikz Blog, Insights';
  context.seoTags();
}
