import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details36',
  templateUrl: './blogs-details36.component.html',
  styleUrls: ['./blogs-details36.component.css']
})
export class BlogsDetails36Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails36Component) {  
  context.title = "The Impact of Blockchain Technology in Today's Industries";
  context.description = 'Blockchain, Blockchain Technology, Applications of Blockchain';
  context.keywords = 'Understand how blockchain technology is reshaping industries, from finance to supply chain, with secure decentralized systems.';
  context.seoTags();
}
