import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmailJobApplicationForm } from '../../../modals/email-job-application-form.modal';
import { ApiResponseCallback } from '../../../modules/core/base/api.service';
import { BaseClassApiService } from '../../../modules/core/base/base-class-api-service';

@Injectable({
  providedIn: 'root'
})
export class ApplyFormService extends BaseClassApiService {

  constructor(private injector: Injector) { super(injector) }
  public _formGroup: FormGroup;

  public getformGroup(): FormGroup {
    return this._formGroup;
  }
  public setformGroup(value: FormGroup) {
    this._formGroup = value;
  }

  getJobTypes(): Subject<any> {
    let subject: Subject<any> = new Subject();
    this.apiService.hitGetApi("https://crm.autocarloan.co.uk/api/WebsiteApi/WebEmailJobOpened/GetAll", {
      onSuccess(response) {
        if (response.length > 0) {
          // self.departmentList = response.filter(e=>e.isActive);
          subject.next(response)
        }
      }, onError(errorCode, errorMsg) {

      }
    })
    // }
    return subject;
  }

  submit(apiResponseCallback: ApiResponseCallback) {
    this.apiService.hitPostApi("https://crm.autocarloan.co.uk/api/WebsiteApi/WebEmailjobApplicationForm", createJsonForUContactUs(this, createRequestJson(this)), apiResponseCallback);
  }
}

function createRequestJson(context: ApplyFormService) {
  console.log(context);
  let requestJson = {};
  Object.keys(context._formGroup.controls).forEach(key => {
    let value = context._formGroup.get(key).value;
    requestJson[key] = value;
  });
  return requestJson;
}

function createJsonForUContactUs(context: ApplyFormService, requestJson: any) {
  let emailContactUs: EmailJobApplicationForm = new EmailJobApplicationForm();
  console.log(requestJson);
  emailContactUs.applyingForJobType = requestJson.applyingForJobType;
  emailContactUs.referal = requestJson.referal;
  emailContactUs.referralCode = requestJson.referralCode;
  emailContactUs.fullName = requestJson.fullName;
  emailContactUs.email = requestJson.email;
  emailContactUs.phoneNumber = requestJson.phoneNumber;
  emailContactUs.linkedInIdLink = requestJson.linkedInIdLink;
  emailContactUs.currentJobDesignation = requestJson.fullName;
  emailContactUs.currentEmployer = requestJson.currentEmployer;
  emailContactUs.totalExperience = requestJson.totalExperience;
  emailContactUs.relevantExperience = requestJson.relevantExperience;
  // emailContactUs.attachment = requestJson.fullName;
  emailContactUs.createdBy = 0;
  emailContactUs.createdDate = new Date().toISOString();
  emailContactUs.modifiedBy = 0;
  emailContactUs.modifiedDate = new Date().toISOString();
  emailContactUs.isActive = true;
  emailContactUs.isDeleted = false;
  console.log(emailContactUs);

  return emailContactUs;
}