import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';

@Component({
  selector: 'app-blogs-details1',
  templateUrl: './blogs-details1.component.html',
  styleUrls: ['./blogs-details1.component.css']
})
export class BlogsDetails1Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails1Component) {  
  context.title = 'New Trends in Business Digital Transformation';
  context.description = 'Stay updated on the latest trends driving digital transformation in businesses today.';
  context.keywords = 'Business Digital Transformation, Trends';
  context.seoTags();
}
