import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details33',
  templateUrl: './blogs-details33.component.html',
  styleUrls: ['./blogs-details33.component.css']
})
export class BlogsDetails33Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails33Component) {  
  context.title = 'Latest Trends in Financial Software Development';
  context.description = 'Financial Software Development, Trends in Financial Software, FinTech';
  context.keywords = 'Discover the latest trends in financial software development, including emerging technologies and solutions in FinTech.';
  context.seoTags();
}
