import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details24',
  templateUrl: './blogs-details24.component.html',
  styleUrls: ['./blogs-details24.component.css']
})
export class BlogsDetails24Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails24Component) {  
  context.title = 'Architecture Design in IT Product Development';
  context.description = 'Explore how effective architecture design in IT product development leads to scalability and performance improvements.';
  context.keywords = 'Architecture Design, IT Product';
  context.seoTags();
}
