import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogsModel } from 'src/app/modals/blogs.modal';

@Component({
  selector: 'app-more-blogs',
  templateUrl: './more-blogs.component.html',
  styleUrls: ['./more-blogs.component.css']
})
export class MoreBlogsComponent implements OnInit {

  constructor(public router: Router, private _httpClient: HttpClient) { }
  blogs: BlogsModel[] = [];
  selectedBlogs: BlogsModel[] = [];
  ngOnInit(): void {
    this._httpClient.get("assets/json/blogs.json")
      .subscribe((response: any) => {
        this.blogs = response;
        const indexes = this.randomNum(0, this.blogs.length);
        indexes.forEach(e => {
          this.selectedBlogs.push(this.blogs[e]);
        })
      })

  }

  randomNum(min, max): number[] {
    var n = [];
    for (var i = 0; i < 4; i++) {
      n.push(Math.floor(Math.random() * max) + min);
    }
    return n;
  }

  showFacebookLink() {
    let FacebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(FacebookLink, "_new")
  }
  showTwitterLink() {
    let twitterLink = "https://twitter.com/intent/tweet?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(twitterLink, "_new")
  }
  showLinkedinLink() {
    let linkedinLink = "https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.hostname + encodeURIComponent(this.router.url);
    window.open(linkedinLink, "_new")
  }

  sendToCal() {
    var d = new Date().getFullYear().toString() + '-' + '0' + (new Date().getMonth() + 1).toString().slice(-2);
    const url = 'https://calendly.com/skillikz/30min?back=1&month=' + d;
    window.open(url, '_blank');
  }

}
