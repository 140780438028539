import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';

@Component({
  selector: 'app-blogs-details12',
  templateUrl: './blogs-details12.component.html',
  styleUrls: ['./blogs-details12.component.css']
})
export class BlogsDetails12Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails12Component) {  
  context.title = 'Combating Financial Fraud with AI & ML';
  context.description = 'Understand how AI and machine learning are being used to combat financial fraud and protect businesses from significant losses.';
  context.keywords = 'Financial Fraud, AI, Machine Learning';
  context.seoTags();
}
