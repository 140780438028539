import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details51',
  templateUrl: './blogs-details51.component.html',
  styleUrls: ['./blogs-details51.component.css']
})
export class BlogsDetails51Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails51Component) {  
  context.title = 'Technology Adoption: A Sign of Top-Performing Companies';
  context.description = "Discover why technology adoption is a key indicator of top-performing companies in today's digital landscape.";
  context.keywords = 'Technology Adoption, High Performance';
  context.seoTags();
}
