import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details54',
  templateUrl: './blogs-details54.component.html',
  styleUrls: ['./blogs-details54.component.css']
})
export class BlogsDetails54Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails54Component) {  
  context.title = 'The Integral Role of People in Digital Transformation';
  context.description = 'Explore the crucial role of people and leadership in driving successful digital transformation initiatives.';
  context.keywords = 'People in Digital Transformation';
  context.seoTags();
}
