import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details9',
  templateUrl: './blogs-details9.component.html',
  styleUrls: ['./blogs-details9.component.css']
})
export class BlogsDetails9Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails9Component) {  
  context.title = 'What is AI and Its Business Applications?';
  context.description = "Get an overview of AI and its wide-ranging applications in modern business operations and industries.";
  context.keywords = 'AI, Business Applications';
  context.seoTags();
}
