import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details20',
  templateUrl: './blogs-details20.component.html',
  styleUrls: ['./blogs-details20.component.css']
})
export class BlogsDetails20Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails20Component) {  
  context.title = 'Latest Practices in IT Product Development and Testing';
  context.description = 'Discover the latest best practices in IT product development and testing to improve quality and innovation.';
  context.keywords = 'IT Product Development, Testing';
  context.seoTags();
}
