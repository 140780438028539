import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details53',
  templateUrl: './blogs-details53.component.html',
  styleUrls: ['./blogs-details53.component.css']
})
export class BlogsDetails53Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails53Component) {  
  context.title = 'The Changing Landscape of Competitive Advantage';
  context.description = 'Understand how the digital age is reshaping competitive advantage and the new drivers businesses need to focus on.';
  context.keywords = 'Competitive Advantage, Digital Age';
  context.seoTags();
}
