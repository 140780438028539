import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details29',
  templateUrl: './blogs-details29.component.html',
  styleUrls: ['./blogs-details29.component.css']
})
export class BlogsDetails29Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails29Component) {  
  context.title = 'Data-Driven Architecture in Software Development';
  context.description = 'Understand the benefits and challenges of implementing data-driven architecture in software development.';
  context.keywords = 'Data-Driven Architecture, Software';
  context.seoTags();
}
