import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details7',
  templateUrl: './blogs-details7.component.html',
  styleUrls: ['./blogs-details7.component.css']
})
export class BlogsDetails7Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails7Component) {  
  context.title = 'New Trends in Serverless Computing';
  context.description = 'Explore the latest trends in serverless computing and how businesses can leverage this technology for better scalability and performance.';
  context.keywords = 'Serverless Computing, Trends';
  context.seoTags();
}
