<section class="bannerSectionCareer topMargin">
    <div class="container-main">
        <app-breadcrumb></app-breadcrumb>
        <div class="contentDetails">
          <h1><span>Career</span></h1>
          <div class="row-flex">
            <div class="items-col-8">
              <p>At Skillikz, we constantly look for talents to join our growing team. As a consulting firm, we strongly believe
                in importance of developing individuals, both our staffs and customers. So, if you are looking to join an
                organisation with an open culture and wanting to contribute to transform businesses, browse through our current
                vacancies.</p>
              <p class="mb-0">If you are unable to find anything that matches your skills, drop us an email and we will contact
                you when something suitable comes up.</p>
            </div>
          </div>
        </div>
      </div>    
  </section>


  <section class="commonSection marginNone border-b bottomBg-">
  
    <div class="container-main">
      <h2 class="large">All Job Openings</h2>
      <div class="targetCard">
        <div class="row-flex">
          <div class="items-col-3">
            <div class="card-white">
              <h2>Quality Engineer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for experienced QA professional with 5-9 years' expertise
                in diverse testing methodologies, automation, Agile environments, database proficiency...</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/quality-engineer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Golang Software Engineer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for Golang Developer to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/golang-software-engineer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>UI Engineers – ReactJS, NodeJS</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for UI Engineers to join our team, who will work closely with development team to develop new system capabilities as defined within the technical/ functional specifications.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/ui-engineers-reactjs-nodejs" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Sr. Engineer – DevOps</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for an innovative, experienced, and talented senior software engineer who will work closely with business unit and development team to develop new system capabilities as defined within the technical/ functional specifications.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/sr-engineer-devops" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Product Owner (UK)</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are recruiting passionate Product Owners to join our expanding team in London. You will work with business leaders to turn software visions into reality, guiding designers and engineers to deliver exceptional user experiences, processes, and technologies.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/product-owner-uk" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Big Data Consultant</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">Join Skillikz as a Big Data Consultant. Join hands-on projects, mentor a dynamic team, and engage in high-level client discussions. 7+ years of Informatica tools experience required. Elevate your career with us!</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/big-data-consultant" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>.NET Developer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">A technical professional with hands-on experience writing quality code and understanding business in Fintech and retails industry.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/dot-net-developer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Sr. Java Developer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for a Java Developer with 4-6 years of core development experience.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/sr-java-developer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white">
              <h2>Java Developer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for a Java Developer with 2-3 years of core development experience.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/java-developer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Chaos & Resiliency Test Engineer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">We are looking for someone with Strong SRE & DevOps experience to help us test the resiliency of our application by applying the Chaos engineering best practices.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/chaos-resiliency-test-engineer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white">
              <h2>Business Analyst</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="open">Open</span>
              </div>
              <p class="mb-5">Looking for a professional who can understand the business very well in FinTech, Retail industry. Someone who can identify and analyze areas for improvement and define the requirements of the business.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/business-analyst" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>.NET Technical Architect</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="closed">Closed</span>
              </div>
              <p class="mb-5">We are looking for a dot Net Architect who can understand the customer business in Fintech and Retail industry and provide a scalable business solution that can add value to the business.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/dot-net-technical-architect" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem fade-in-bottom">
              <h2>Trainee Project Manager (Intern)</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="closed">Closed</span>
              </div>
              <p class="mb-5">Skillikz is a business consulting firm based in London offering research services to a diverse range of clients around the world.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/trainee-project-manager" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          <div class="items-col-3">
            <div class="card-white scroll-elem slide-right">
              <h2>Trainee Data Analyst (Consulting Projects Division)</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="closed">Closed</span>
              </div>
              <p class="mb-5">Skillikz is a business consulting firm based in London offering research services to a diverse range of clients around the world.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/trainee-data-analyst" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>Customer Service Associate (Legal)</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="closed">Closed</span>
              </div>
              <p class="mb-5">To provide administration and assistance to a Conveyancing Property Lawyer and Team in relation to the progression of active conveyancing files. To carry out further ad hoc duties as required by the management.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/customer-service-associate" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>
          
          <div class="items-col-3">
            <div class="card-white scroll-elem slide-left">
              <h2>E-Learning Developer</h2>
              <div class="status">
                <span>Full Time</span>
                <span class="closed">Closed</span>
              </div>
              <p class="mb-5">This is a new role for the organisation, Skillikz are now searching for a E-learning
                developer who is motivated to join their well-capitalised and ambitious company for the next phase of growth.</p>
              <div class="btn-fixed">
                <button type="button" routerLink="/all-job-openings/e-learning-developer" class="btn-blue" aria-label="Get Full Details">Get Full Details</button>
              </div>
            </div>
          </div>

          <div class="items-col-3">
            <div class="card-white cardHighlight scroll-elem fade-in-bottom">
              <h2>Can't find anything you looking for?</h2>
              <div class="btn-fixed text-right">
                <a href="javascript:void(0)" routerLink="/all-job-openings" role="button" class="link-md" >See Full Job Opening List</a>
              </div>
            </div>
          </div>

        </div>
      </div>    
  
    </div>

  </section>
  



  
  <section class="gradientTopTo border-b">
  
    <section class="commonSection marginNone border-b bottomBg-">
  
      <div class="container-main">

        <div class="mb-60">
          <!-- Why Work with Us component here -->
          <app-why-work-with-us></app-why-work-with-us>         
        </div>

        <div class="mb-60">
          <div class="items-col-1 mb-15">
          <h2 class="large">Globally We Are</h2>
          </div>
          <div class="diagram-wp scroll-elem fade-in-bottom">
            <img src="assets/images/globally-img.svg" class="onlyWeb" alt="Globally Connected IT Solutions by Skillikz" width="1032" height="472">
            <img src="assets/images/globally-mbl-img.svg" class="onlyMobile" alt="Skillikz Mobile IT Solutions Globally" width="320"
              height="1030">
          </div>
        </div>
  
        
        
        <div class="mb-20">
          <h2 class="large">Read More About Us</h2>
          <div class="moreAboutLinks scroll-elem slide-left">
            <ul>
              <li><a routerLink="/who-we-are">Who We Are</a></li>
              <li><a routerLink="/esg">ESG</a></li>
            </ul>
          </div>
        </div>
    
      </div>
  
    </section>
  
  </section>
  
  