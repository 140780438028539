import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/modules/core/base/base-class';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent extends BaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}


function init(context: CareerComponent) {  
  context.title = 'Career Opportunities at Skillikz';
  context.description = 'Explore career opportunities at Skillikz and join our dynamic team in empowering learners worldwide.';
  context.keywords = 'Skillikz Careers';
  context.seoTags();
}
