import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details34',
  templateUrl: './blogs-details34.component.html',
  styleUrls: ['./blogs-details34.component.css']
})
export class BlogsDetails34Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails34Component) {  
  context.title = "Understanding Robotic Process Automation (RPA)";
  context.description = 'Robotic Process Automation, RPA in Business, RPA Benefits';
  context.keywords = 'Learn how RPA is revolutionizing business operations by automating tasks and improving efficiency across industries.';
  context.seoTags();
}
