<section class="commonSection topMargin pt-20">
  <div class="container-main">
      <app-breadcrumb></app-breadcrumb>

      <div class="row-flex">
          <div class="items-lg-col-8">                
            <div class="">
              <h1><span>Sr. Java</span> Developer</h1>
            </div>
        
            <div class="job-details">
              <ul>
                <li>Location: <span>UK</span></li>
                <li>Reports to: <span>Project Manager</span></li>
                <li>Salary: <span>Competitive</span></li>
                <li>Update On: <span>08 Mar, 2023</span></li>
              </ul>
            </div>
  
          <div class="work-mode">
            <p>Work Mode: <span>Full Time</span> Job Status: <span class="open">Open</span></p>
          </div>
          </div>

          <!-- share-component-here -->
          <div class="items-lg-col-3 topShareIcon">
              <app-share-on-details></app-share-on-details>
          </div>

          <div class="items-lg-col-8">
              <hr class="border mb-15">

              <div class="related-service mb-20">
                <h2 class="text-black mb-0">We are looking for a Java Developer with 4-6 years of core development experience.</h2>  
              </div>
              <hr class="border mb-20">
              
              <div class="">
                <h2 class="text-black">Skill Required</h2>
                <p class="mb-5">Required Skill Set</p>
                <ul class="list-black">
                  <li>Java 8/ Java 11</li>
                  <li>Spring boot</li>
                  <li>Hibernate</li>
                  <li>Kafka</li>
                  <li>Jenkins</li>
                  <li>Git</li>
                  <li>Good understanding of Rest APIs</li>
                  <li>Good knowledge of SQL</li>
                  </ul>
              </div>
        
              <div class="">
                <p class="mb-5">Desirable Skills:</p>
                <ul class="list-black">
                  <li>Oracle/Postgres/Couchbase/DB2</li>
                  <li>Go</li>
                  <li>Splunk</li>
                  <li>CI/CD tooling</li>
                  <li>Microservice</li>
                  </ul>
              </div>
        
              <div class="">
                <h2 class="text-black">Salary and Benefits</h2>
                <ul class="list-black">
                  <li>Competitive salary depending on skills and experience</li>
                  <li>Flexible working hours</li>
                  <li>Remote working available for this role</li>
                </ul>
              </div>             

          </div>

      </div>  
  </div>
</section>


<section class="gradientTopTo">
  <section class="commonSection marginNone border-b bottomBg-">
      <div class="container-main">
          <div class="mb-60">
              <app-apply-form></app-apply-form>
          </div>
          <app-why-work-with-us></app-why-work-with-us>
      </div>
  </section>
</section>

<section class="sectionForm border-b">
  <div class="container-main">
      <div class="row-flex">
          <div class="custom-col-55">
              <div class="formContent scroll-elem slide-left">
                  <h2 class="mb-15">Contact us today and embark on a digital journey <span>like never before. Let's
                          redefine your online presence together.</span></h2>
                  <p class="small">*By clicking on "submit' button you agree to that we may contact you by phone,
                      email or text message.</p>
              </div>
          </div>
          <div class="custom-col-45">
              <!-- Contact Us form component -->
              <app-form></app-form>
          </div>
      </div>
  </div>
</section>
