
<section class="sectionInnerBanner topMargin">
    <div class="container-main">
  
    <app-breadcrumb></app-breadcrumb>
  
      <div class="topInnerBanner">
        <div class="itemTopContent">
          <div class="">
            <h1><span>Who we are</span></h1>
            <div class="onlyForMbl">
              <img src="assets/images/who-we-are-img.webp" alt="Who we are"
              width="336" height="336" onerror="this.src='assets/images/who-we-are-img.png'">
            </div>
            <p class="mb-0">We specialize in Organizational Development, with a rich pedigree in Information Technology. Our Goal is to help our customers embrace the change with least disruption and achieve exponential growth. </p>
         <br/>
            <p>
            We leverage technologies to enhance digital experiences in the fast-paced technology ecosystem that keeps our customers a step ahead with a futuristic approach throughout the Business Digital Transformation journey and afterwards.
          </p>
          </div>
        </div>
        <div class="itemsImage">
          <div class="">
            <img src="assets/images/who-we-are-img.webp" alt="Who we are"
            width="336" height="336" onerror="this.src='assets/images/who-we-are-img.png'">
          </div>
        </div>
      </div>
  
    </div>
  </section>
  
  <section class="sectionCommonLightBlue marginNone border-b">
    <div class="container-main">
    
      <div class="row-flex">
  
        <div class="items-col-3">
          <h2 class="large">Goal</h2>
          <p>Our Goal is to help our customers embrace the change with least disruption and achieve exponential growth.</p>
        </div>
        <div class="items-col-3">
          <h2 class="large">Mission</h2>
          <p>To build a seamless journey for Customers with maximum business value without disrupting existing business model.</p>
        </div>
        <div class="items-col-3">
          <h2 class="large">Vision</h2>
          <p>Our vision is to develop technology-led solutions with business-oriented approach, backed by predictable and consistent delivery.</p>
        </div>
  
      </div>
    </div>
  </section>
  
  
  
  
  <section class="gradientBg border-b">
  
    <section class="commonSection marginNone border-b">
      <div class="container-main">
        <div class="">
          <h2 class="large">Industries We Specialize In</h2>
          <div class="row-flex mb-40">
            <div class="items-col-8">
              <p>With more than 12 years of experience and expertise across various industry verticals, we provide an edge with
                Digital Transformation in the markets you excel and aim to conquer, globally.</p>
            </div>
          </div>

          <div class="mb-20">
          
            <div class="images-wrapper-call-3 scroll-elem fade-in-bottom">
              <div class="">
                <div class="card-images">
                  <img src="assets/images/fintech-img.webp" alt="Fintech img" width="504" height="640"
                    onerror="this.src='assets/images/fintech-img.png'">
                  <div class="">
                    <h3 class="mb-0">Fintech</h3>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="card-images">
                  <img src="assets/images/retail-img.webp" alt="Retail img" width="504" height="640"
                    onerror="this.src='assets/images/retail-img.png'">
                  <div class="">
                    <h3 class="mb-0">Retail</h3>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="card-images">
                  <img src="assets/images/logistics-img.webp" alt="Logistics img" width="504" height="640"
                    onerror="this.src='assets/images/logistics-img.png'">
                  <div class="">
                    <h3 class="mb-0">Logistics</h3>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="card-images">
                  <img src="assets/images/utilities-img.webp" alt="Utilities img" width="504" height="640"
                    onerror="this.src='assets/images/utilities-img.png'">
                  <div class="">
                    <h3 class="mb-0">Utilities</h3>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="card-images">
                  <img src="assets/images/manufacturing-img.webp" alt="Manufacturing img" width="504" height="640"
                    onerror="this.src='assets/images/manufacturing-img.png'">
                  <div class="">
                    <h3 class="mb-0">Manufacturing</h3>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="card-images">
                  <img src="assets/images/education-img.webp" alt="Educations img" width="504" height="640"
                    onerror="this.src='assets/images/education-img.png'">
                  <div class="">
                    <h3 class="mb-0">Education</h3>
                  </div>
                </div>
              </div>
          
            </div>
          
          </div>
        </div> 

      </div>
    </section>

  
    <section class="commonSection marginNone border-b">
      <div class="container-main">
        <div class="mb-60">
          <h2 class="large">We Serve Globally</h2>      
          <div class="diagram-wp scroll-elem fade-in-bottom">
            <img src="assets/images/globally-img.svg" class="onlyWeb" alt="Globally Connected IT Solutions by Skillikz" width="1032" height="472">
            <img src="assets/images/globally-mbl-img.svg" class="onlyMobile" alt="Skillikz Mobile IT Solutions Globally" width="320"
              height="1030">
          </div>
        </div>  
  
        <div class="mb-20">
          <h2 class="large">Read More About Us</h2>
          <div class="moreAboutLinks scroll-elem slide-left">
            <ul>
              <li><a routerLink="/our-values">Our Values</a></li>
              <li><a routerLink="/esg">ESG</a></li>
            </ul>
          </div>
        </div>
        
      </div>
    </section>   
     
  </section>
  
  