import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details8',
  templateUrl: './blogs-details8.component.html',
  styleUrls: ['./blogs-details8.component.css']
})
export class BlogsDetails8Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails8Component) {  
  context.title = 'Application of Multi-cloud Computing in Business';
  context.description = 'Learn how multi-cloud computing enables businesses to optimize their IT infrastructure and improve performance.';
  context.keywords = 'Multi-cloud Computing, Business';
  context.seoTags();
}
