import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details6',
  templateUrl: './blogs-details6.component.html',
  styleUrls: ['./blogs-details6.component.css']
})
export class BlogsDetails6Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails6Component) {  
  context.title = 'Application of Edge Computing in Business';
  context.description = 'Learn how edge computing is revolutionizing businesses by enabling faster data processing and decision-making.';
  context.keywords = 'Edge Computing, Business Applications';
  context.seoTags();
}
