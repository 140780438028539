import { Component, OnInit } from '@angular/core';
import { BlogsBaseClass } from 'src/app/modules/core/base/blogs-base-class';


@Component({
  selector: 'app-blogs-details5',
  templateUrl: './blogs-details5.component.html',
  styleUrls: ['./blogs-details5.component.css']
})
export class BlogsDetails5Component extends BlogsBaseClass implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: BlogsDetails5Component) {  
  context.title = 'Application of Hybrid-cloud in Business Processes';
  context.description = "Discover how hybrid-cloud solutions are transforming business processes for greater flexibility and cost efficiency.";
  context.keywords = 'Hybrid-cloud, Business Processes';
  context.seoTags();
}
